import { clsx } from 'clsx';
import * as React from "react";
import { lazy, memo } from "react";
import Accordion from "../components/accordion";
import Dump from "../components/dump";
import { decodeHTMLEntities, strip_tags } from "../helpers/utils.ts";
import LatestNews from "../layout/latestNews.tsx";
import GoogleMap from "../layout/map.tsx";
import Service from "../layout/service.tsx";
import { ItemType, type PageRenderedItemData } from "../rest.interface";
import { ContentRenderMode } from "./contentRenderMode";
import RenderContent from "./renderContent";
import Vimeo from '@u-wave/react-vimeo';
import {
	getBgColorClass,
	getBgImageClass,
	getMarginClasses,
	getPaddingClasses,
	prefixClassArray,
	responsiveHelper
} from "./renderFunctions";
import RenderSlider from "./renderSlider.tsx";

const Contact = lazy(() => import('../contact/contact'));
const ContactWithServices = lazy(() => import('../contact/contactWithServices'));

type Props = {
	item: PageRenderedItemData
	itemId: string
	itemLayoutClass?: string[]
}

function renderBlock(item: PageRenderedItemData = null): JSX.Element {
	if (item?.type) {
		switch (item.type) {
			case ItemType.CODE:
				if (item.rendered) {
					return <span dangerouslySetInnerHTML={{__html: decodeHTMLEntities(strip_tags(item.rendered))}}/>
				} else if (item.content) {
					return <span dangerouslySetInnerHTML={{__html: decodeHTMLEntities(strip_tags(item.content))}}/>
				}
				return null;

			case ItemType.TEXT:
				if (item.rendered) {
					return <span dangerouslySetInnerHTML={{__html: item.rendered}}/>
				} else if (item.content) {
					return <span dangerouslySetInnerHTML={{__html: item.content}}/>
				}
				return null;

			case ItemType.FAQ:
				if (item.rendered) {
					return <Accordion header={<p>{item.title}</p>}>
						<span dangerouslySetInnerHTML={{__html: item.rendered}}/>
					</Accordion>
				} else if (item.content) {
					return <Accordion header={<p>{item.title}</p>}>
						<span dangerouslySetInnerHTML={{__html: item.content}}/>
					</Accordion>
				}
				return null;

			case ItemType.REUSE:
				return <RenderContent content={item?.page?.content} mode={ContentRenderMode.REUSE_BLOCK}/>;

			case ItemType.CONTACT:
				return (item.content ? <ContactWithServices content={item.content}/> : <Contact content={item.content}/>);

			case ItemType.NEWS:
				return <LatestNews item={item}/>

			case ItemType.SERVICE:
				return item?.page && <Service item={item.page}/>

			case ItemType.MAP:
				return <GoogleMap item={item}/>

			case ItemType.VIMEO:
				return <>{item.vimeoId &&
					<div style={{padding: "56.25% 0 0 0", position: "relative"}}>
						<Vimeo
							style={{width: "100%", height: "100%"}}
							className="absolute inset-0 w-full h-full vimeoContainer"
							video={item.vimeoId}
							autoplay={item.vimeoAutoplay}
							muted={item.vimeoMute}
							showTitle={item.vimeoShowTitle}
							showPortrait={item.vimeoShowPortrait}
							color={item.vimeoColor}
							paused={item.vimeoPaused}
							loop={item.vimeoLoop}
						/>
					</div>
				}</>

			case ItemType.SLIDER:
				return <RenderSlider item={item}/>
		}
	}

	return <>
		<Dump value={item.type} label={"Missing renderer"}/>
		<Dump value={item.content}/>
	</>;
}

function renderBlockWrapper(item = null): JSX.Element {
	let toReturn = renderBlock(item);

	if (item?.wrapper) {
		switch (item.wrapper) {
			case 'div':
				return <div>{toReturn}</div>;
			case 'headerBox':
				return <div className="headerBox">
					<div>{toReturn}</div>
				</div>;
			case 'blockquote':
				return <blockquote className="md:text-center">
					<div className="stylistic-quote-mark-before font-medium" aria-hidden="true">&ldquo;</div>
					{toReturn}
					<div className="stylistic-quote-mark-after font-medium" aria-hidden="true">&bdquo;</div>
				</blockquote>;
		}
	}

	return toReturn;
}

function RenderItem({item, itemId, itemLayoutClass = [], ...otherProps}: Props) {
	let itemClass = ['h-full'];

	if (item.type !== 'reuse') { // this stops column overflow, bg colors will be set anyway by the reused content
		//itemStyle = getBgStyle(item);
		itemClass = itemClass.concat(getBgImageClass(item), getBgColorClass(item));
	}

	if (item?.size) {
		itemLayoutClass = itemLayoutClass.concat(responsiveHelper(item.size));
	}

	if (item?.order) {
		itemLayoutClass = itemLayoutClass.concat(responsiveHelper(item.order, 'order-'));
	}

	if (item?.class) {
		itemClass.push(item.class);
	}

	if (item?.textAlign) {
		itemLayoutClass = itemLayoutClass.concat(responsiveHelper(item.textAlign));
	}

	if (item?.textClass) {
		itemLayoutClass.push(item.textClass);
	} else if (item?.textColor) {
		itemLayoutClass.push('text-[' + item.textColor + ']');
	}

	itemLayoutClass = itemLayoutClass.concat(getMarginClasses(item));
	itemClass = itemClass.concat(getPaddingClasses(item));

	return (<>
		{/*<Dump label="itemLayoutClass" value={itemLayoutClass}/>*/}

		<div id={itemId} className={clsx(prefixClassArray(itemLayoutClass))} {...otherProps}>
			<div className={clsx(prefixClassArray(itemClass))}>
				{renderBlockWrapper(item)}
			</div>
		</div>
	</>);
}

export default memo(RenderItem)
